<template>
  <footer class="acura-footer">
    <div class="acura-footer__container">
      <div class="acura-footer__info">
        <strong>For help, please contact the contest Concierge:</strong><br>
        9:00 a.m. - 5:00 p.m. EDT, Monday - Friday<br>
        <a
          class="acura-footer__link"
          href="tel:18556538061"
        >(855) 653-8061</a><br>
        <a
          class="acura-footer__link"
          href="mailto:info@acurastorytelling.com"
        >info@AcuraStorytelling.com</a>
      </div>
      <div class="acura-footer__logos">
        <img
          class="acura-footer__logo"
          src="@/assets/img/brand--acura.svg"
          alt="Logo Acura"
        >
        <img
          class="acura-footer__logo"
          src="@/assets/img/acura-award-excellence.svg"
          alt="Award - Acura Council of Sales Excellence"
        >
      </div>
      <p class="acura-footer__copy">
        &copy; 2023 Acura. Acura reserves the right to modify, amend, cancel,
        or revoke, in whole or in part, this offer at any time and without prior
        notice. In all matters pertaining to interpretation and application of
        any rule or phase of the program, the decision of Acura will be final.
        Trip, awards, and activities cannot be transferred or exchanged.
      </p>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
.acura-footer {
  &__container {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex-wrap: wrap;
    gap: 40px;
    padding: 30px 60px 20px;
    text-align: left;

    @media screen and (min-width: 1024px) {
      flex-direction: row;
      align-items: flex-end;
      justify-content: space-between;
      gap: 0;
    }
  }

  &__logos {
    display: flex;
    gap: 40px;
  }

  &__logo:first-child {
    width: calc(70% - 10px);
  }

  &__logo:last-child {
    width: calc(30% - 10px);
  }

  &__info {
    line-height: 36px;
    @media screen and (min-width: 1024px) {
      flex-basis: 50%;
      order: 0;
    }
  }

  &__copy {
    display: block;
    width: 100%;
    padding: 20px 0;
    border-top: 1px solid #7e7e7e;
    order: 2;

    @media screen and (min-width: 1024px) {
      margin-top: 40px;
    }
  }
}
</style>
