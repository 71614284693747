<template>
  <header class="acura-header">
    <a class="acura-header__logo" href="#" title="Acura Storytelling Competition">
      <img class="acura-header__logo" src="@/assets/img/brand--acura.svg" alt="Logo Acura">
    </a>
  </header>
</template>

<style lang="scss" scoped>
.acura-header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px 0 16px;
  border-bottom: 1px solid #7e7e7e;

  a {
    display: block;
  }

  img {
    max-width: 160px;
  }

  @media only screen and (min-width: 1024px) {
    padding: 40px 0 36px;

    img {
      max-width: none;
    }
  }
}
</style>
