<template>
  <section class="acura-finalists">
    <div class="acura-finalists__container">
      <h4 class="acura-finalists__title">NATIONAL FINALISTS</h4>
      <div class="acura-finalists__cards">
        <FinalistCard
          v-for="FINALIST, i in FINALISTS"
          class="acura-finalists__card"
          :info="FINALIST"
          :key="i"
        />
      </div>
    </div>
  </section>
</template>

<script setup>
import FinalistCard from './FinalistCard.vue';

const FINALISTS = [
  {
    firstName: 'Chazz',
    lastName: 'Hodoh',
    dealer: 'AUTONATION ACURA SOUTH BAY',
  },
  {
    firstName: 'Kierstin',
    lastName: 'Steinlicht',
    dealer: 'DON AYRES ACURA',
  },
  {
    firstName: 'Heather',
    lastName: 'McGowan',
    dealer: 'JEFF WYLER ACURA OF FAIRFIELD',
  },
  {
    firstName: 'Preston',
    lastName: 'Dean',
    dealer: 'DON AYRES ACURA',
  },
  {
    firstName: 'Meghan',
    lastName: 'Pakenham',
    dealer: 'AUTOSPORT ACURA OF DENVILLE',
  },
  {
    firstName: 'Louis',
    lastName: 'Levene',
    dealer: 'LEITH ACURA',
  },
  {
    firstName: 'Emmitt',
    lastName: 'Scott',
    dealer: 'HENDRICK ACURA',
  },
  {
    firstName: 'Steven',
    lastName: 'Padilla',
    dealer: 'METRO ACURA',
  },
  {
    firstName: 'Chris',
    lastName: 'Knutson',
    dealer: 'NIELLO ACURA',
  },
  {
    firstName: 'Jennifer',
    lastName: 'Sela',
    dealer: 'OPEN ROAD ACURA OF WAYNE',
  },
  {
    firstName: 'Justin',
    lastName: 'Felix',
    dealer: 'PARK AVE. ACURA',
  },
  {
    firstName: 'Tony',
    lastName: 'Tran',
    dealer: 'VANDERGRIFF ACURA',
  },
];
</script>

<style lang="scss" scoped>
.acura-finalists {
  &__container {
    padding-top: 40px;
    padding-bottom: 20px;
    background-color: #000;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
    font: 300 32px/56px 'Avenir', sans-serif;
    letter-spacing: 1.15px;
  }

  @media screen and (min-width: 768px) {
    &__title {
      font-size: 60px;
      letter-spacing: 2.16px;
    }

    &__cards {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
    }

    &__card {
      width: calc(33.33% - 20px);
    }
  }

  @media screen and (min-width: 1024px) {
    margin-top: -60px;

    &__card {
      width: calc(25% - 20px);
    }
  }

  @media screen and (min-width: 1600px) {
    &__container {
      padding: 60px 40px;
      border: 1px solid #fff;
    }
  }
}
</style>
