<template>
  <h1 class="acura-intro__title">
    <span>2023 Storytelling Competition</span>
  </h1>
</template>

<style lang="scss" scoped>
.acura-intro {
  &__title {
    position: relative;
    max-width: 530px;
    margin: 0 auto;
    padding: 14px 25px;
    background: url('@/assets/img/bg__intro-title.webp') no-repeat center top;
    background-size: 100% 220px;
    font: 30px/48px 'Acura Bespoke', sans-serif;
    font-weight: normal;
    text-transform: uppercase;

    span {
      position: relative;
      z-index: 1;
    }

    @media screen and (min-width: 530px) {
      &::before,
      &::after {
        content: '';
        position: absolute;
        top: 0;
        bottom: 0;
        width: 100px;
        background: rgb(0,0,0);
      }

      &::before {
        left: 0;
        background: linear-gradient(90deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
      }

      &::after {
        right: 0;
        background: linear-gradient(90deg, rgba(0,0,0,0) 0%, rgba(0,0,0,1) 100%);
      }
    }

    @media screen and (min-width: 1024px) {
      display: inline-block;
      max-width: none;
      padding: 20px 100px;
      background-size: 100% 160px;
      font-size: 48px;
    }

    @media screen and (min-width: 1480px) {
      background-position: center 28px;
      background-size: 1480px 140px;
      font-size: 76px;
      line-height: initial;

      &::before,
      &::after {
        width: 200px;
      }
    }

    @media screen and (min-width: 1810px) {
      background-position: center 40px;
      font-size: 96px;

      &::before {
        left: 100px;
      }

      &::after {
        right: 100px;
      }
    }
  }
}
</style>
