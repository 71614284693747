<template>
  <section class="acura-zone">
    <div class="acura-zone__container">
      <h4 class="acura-zone__title">ZONE WINNERS</h4>
      <div class="acura-zone__cards">
        <ZoneCard title="Zone 1" :items="ZONE_1"/>
        <ZoneCard title="Zone 2" :items="ZONE_2"/>
        <ZoneCard title="Zone 3" :items="ZONE_3"/>
        <ZoneCard title="Zone 4" :items="ZONE_4"/>
      </div>
    </div>
    <div class="acura-zone__info">
      <p>
        <i>
          * Must travel to compete in National Competition to receive
          National prizes. Participants for the National Competition
          were chosen from ALL valid video submissions regardless of Zone.
        </i>
      </p>
      <img
        class="acura-zone__img"
        src="@/assets/img/bg_footer.png"
        alt="A beautiful place"
      />
    </div>
  </section>
</template>

<script setup>
import ZoneCard from './ZoneCard.vue';

const ZONE_1 = [
  {
    place: 'First Place',
    name: 'Chazz Hodoh',
    dealer: 'AUTONATION ACURA SOUTH BAY',
  },
  {
    place: 'Second Place',
    name: 'Steven Padilla',
    dealer: 'METRO ACURA',
  },
  {
    place: 'Third Place',
    name: 'Chris Knutson',
    dealer: 'NIELLO ACURA',
  },
];

const ZONE_2 = [
  {
    place: 'First Place',
    name: 'Kierstin Steinlicht',
    dealer: 'DON AYRES ACURA',
  },
  {
    place: 'Second Place',
    name: 'Heather McGowan',
    dealer: 'JEFF WYLER ACURA OF FAIRFIELD',
  },
  {
    place: 'Third Place',
    name: 'Preston Dean',
    dealer: 'DON AYRES ACURA',
  },
];

const ZONE_3 = [
  {
    place: 'First Place',
    name: 'Meghan Pakenham',
    dealer: 'AUTOSPORT ACURA OF DENVILLE',
  },
  {
    place: 'Second Place',
    name: 'Jennifer Sela',
    dealer: 'OPEN ROAD ACURA OF WAYNE',
  },
  {
    place: 'Third Place',
    name: 'Justin Felix',
    dealer: 'PARK AVE. ACURA',
  },
];

const ZONE_4 = [
  {
    place: 'First Place',
    name: 'Louis Levene',
    dealer: 'LEITH ACURA',
  },
  {
    place: 'Second Place',
    name: 'Emmitt Scott',
    dealer: 'HENDRICK ACURA',
  },
  {
    place: 'Third Place',
    name: 'Anibal Roman',
    dealer: 'JENKINS ACURA',
  },
];
</script>

<style lang="scss" scoped>
.acura-zone {
  &__container {
    padding-top: 40px;
    border-top: 1px solid #fff;
    border-bottom: 1px solid #fff;
    text-align: center;
  }

  &__title {
    margin-bottom: 40px;
    font: 300 32px/56px 'Avenir', sans-serif;
    letter-spacing: 1.15px;
  }

  &__info p {
    max-width: 365px;
    margin: 0 auto;
    padding-top: 40px;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.58px;
  }

  @media screen and (min-width: 768px) {
    &__title {
      margin-bottom: 80px;
      font-size: 60px;
      letter-spacing: 2.16px;
    }

    &__info p {
      position: relative;
      max-width: 1400px;
      margin-bottom: -60px;
      font-size: 24px;
      line-height: 38px;
      letter-spacing: 0.86px;
      z-index: 1;
    }

    &__img {
      width: 100%;
    }
  }

  @media screen and (min-width: 1600px) {
    &__container {
      padding: 60px 40px;
      border: 1px solid #fff;
    }

    &__info p {
      margin-bottom: -160px;
      padding-top: 80px;
    }
  }
}
</style>
